import React, { useState, useRef, useEffect } from "react";

const RenderCarousel = ({ file, type }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentViewerIndex, setCurrentViewerIndex] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(1);

    const containerRef = useRef(null);
    const [containerWidth, setContainerWidth] = useState(0);

    const goToNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === file.length - 1 ? 0 : prevIndex + 1
        );
    };

    const goToPrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? file.length - 1 : prevIndex - 1
        );
    };

    const goToNextViewer = () => {
        setCurrentViewerIndex((prevIndex) =>
            prevIndex === file.length - 1 ? 0 : prevIndex + 1
        );
    };

    const goToPrevViewer = () => {
        setCurrentViewerIndex((prevIndex) =>
            prevIndex === 0 ? file.length - 1 : prevIndex - 1
        );
    };

    const openImageViewer = (index) => {
        setCurrentViewerIndex(index);
        setIsViewerOpen(true);
    };

    const closeImageViewer = () => {
        setIsViewerOpen(false);
        setZoomLevel(1); // Reset zoom level
    };

    const handleZoomIn = () => {
        setZoomLevel((prev) => Math.min(prev + 0.5, 3)); // Max zoom 3x
    };

    const handleZoomOut = () => {
        setZoomLevel((prev) => Math.max(prev - 0.5, 1)); // Min zoom 1x
    };

    useEffect(() => {
        const adjustWidth = () => {
            if (containerRef.current) {
                setContainerWidth(containerRef.current.offsetWidth);
            }
        };
        adjustWidth();
        window.addEventListener("resize", adjustWidth);
        return () => {
            window.removeEventListener("resize", adjustWidth);
        };
    }, []);

    return (
        <>
            <div
                ref={containerRef}
                className="relative w-full overflow-hidden"
                style={{ height: "auto" }}
            >
                <div
                    className="flex transition-transform duration-500"
                    style={{
                        transform: `translateX(-${currentIndex * containerWidth}px)`,
                        width: `${file.length * 100}%`,
                    }}
                >
                    {file.map((image, index) => (
                        <img
                            key={index}
                            src={`${image}`}
                            alt={`file item ${index + 1}`}
                            className="object-contain cursor-pointer"
                            style={{
                                width: `${containerWidth}px`,
                                height: `${type === "gallery" ? "auto" : "400px"}`,
                            }}
                            onClick={() => openImageViewer(index)}
                        />
                    ))}
                </div>
                <button
                    onClick={goToPrev}
                    className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-base-600 text-base-100 p-2 px-3.5 text-2xl rounded-full"
                >
                    &#10094;
                </button>
                <button
                    onClick={goToNext}
                    className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-base-600 text-base-100 p-2 px-3.5 text-2xl rounded-full"
                >
                    &#10095;
                </button>
            </div>
            {isViewerOpen && (
                <div className="fixed inset-0 bg-base-600 bg-opacity-75 flex items-center justify-center z-50">
                    <span
                        className="absolute top-5 right-5 text-white cursor-pointer text-base-100 bg-base-600 p-1 px-3.5 text-4xl rounded-full z-30"
                        onClick={closeImageViewer}
                    >
                        &times;
                    </span>
                    <button
                        className="absolute left-5 text-base-100 bg-base-600 p-2 px-3.5 text-2xl rounded-full z-30"
                        onClick={goToPrevViewer}
                        disabled={file.length <= 1}
                    >
                        &#10094;
                    </button>
                    <div
    className={`relative z-10 flex justify-center items-center ${
        zoomLevel > 1 ? "overflow-auto border-2 border-base-500" : "overflow-hidden"
    } w-[80vw] h-[80vh]`}
    style={{
        scrollbarWidth: "thin",
        scrollbarColor: "#888 #ddd", // Customize scrollbar colors
    }}
>
    <img
        src={`${file[currentViewerIndex]}`}
        className="rounded-lg"
        alt=""
        style={{
            transform: `scale(${zoomLevel})`,
            transformOrigin: "center",
            transition: "transform 0.3s ease",
            objectFit: "contain", // Maintain proportions
            maxWidth: "100%", // Ensure no overflow initially
            maxHeight: "100%",
        }}
    />
</div>

                    <div className="absolute bottom-5 flex space-x-3">
                        <button
                            onClick={handleZoomIn}
                            className="bg-base-600 text-base-100 p-2 rounded-lg"
                        >
                            Zoom In
                        </button>
                        <button
                            onClick={handleZoomOut}
                            className="bg-base-600 text-base-100 p-2 rounded-lg"
                        >
                            Zoom Out
                        </button>
                    </div>
                    <button
                        className="absolute right-5 text-base-100 text-2xl bg-base-600 p-2 px-3.5 rounded-full z-30"
                        onClick={goToNextViewer}
                        disabled={file.length <= 1}
                    >
                        &#10095;
                    </button>
                </div>
            )}
        </>
    );
};

export default RenderCarousel;
