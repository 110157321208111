import React, { useEffect, useState } from "react";
import { loginAndOtpRoutes, resendOtpRoutes } from "../services/authService";
import { useAuth } from "../contexts/AuthProvider";
import { assetsUrl } from "../config/url";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	setLikeContent,
	setLikeProject,
	setProjects,
} from "../store/features/projectSlice";
import { Bounce, toast } from "react-toastify";
import {
	fetchAllProjects,
	handleDownload,
	postLeadToProject,
	postPutFavoriteProject,
} from "../services/projectService";
import { setDownloadState, setReset } from "../store/features/downloadSlice";
import { reset } from "../store/features/authFunctionalitySlice";
import { setOffsets } from "../store/features/filterSlice";
import { postPutFavoriteContent } from "../services/contentService";

const Login = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const projects = useSelector((state) => state.projects?.projects);
	const { loginOpen, closeLogin, setIsLoggedIn } = useAuth();
	const authFunctions = useSelector(
		(state) => state.authFunctions.authFunctions
	);
	const filters = useSelector((state) => state.filters);
	const [step, setStep] = useState(1); // Step 1: Enter Name & Phone, Step 2: Enter OTP
	const [phone, setPhone] = useState("");
	const [name, setName] = useState("");
	const [otp, setOtp] = useState("");
	const [error, setError] = useState("");

	const [sendingOTP, setSendingOTP] = useState(false);
	const [seconds, setSeconds] = useState(0);
	const [resendEnabled, setResendEnabled] = useState(false);
	const [resendingFlag, setResendingFlag] = useState(false);

	const updateScreenSize = () => {
		const width = window.innerWidth;
		if (width >= 1536) {
			return "lg";
		} else if (width >= 1024) {
			return "md";
		} else {
			return "sm";
		}
	};
	const loginPurpose = async () => {
		console.log(authFunctions);
		try {
			if (authFunctions?.purpose === "redirect") {
				navigate(`${authFunctions?.details?.data?.redirectPath}`);
			} else if (authFunctions?.purpose === "function") {
				if (authFunctions?.details?.actionName === "toggleFav") {
					const params = {
						type: filters.type,
						config: filters.config,
						area: filters.area,
						minPrice: filters.minPrice,
						maxPrice: filters.maxPrice,
						possessionStatus: filters.possessionStatus,
						projectOffset: 0,
						contentOffset: 0,
						lastContentPosition: 0,
						screenSize: updateScreenSize(),
					};
					const response = await fetchAllProjects({ params });
					console.log(response.data);
					if (!response.data) {
						loginPurpose();
						return;
					} else {
						dispatch(
							setOffsets({
								newProjectOffset: response.nextProjectOffset,
								newContentOffset: response.nextContentOffset,
								lastContentPosition: response.lastPosition,
							})
						);
						dispatch(setProjects({ ...projects, ...response }));
					}
					const projectId = authFunctions?.details?.data?.projectId;
					const resp = await postPutFavoriteProject(projectId);
					if (resp.message === "Added To Favorites") {
						dispatch(setLikeProject({ projectId: projectId }));
						toast.info(resp.message, {
							position: "top-right",
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: "light",
							transition: Bounce,
						});
					} else if (resp.message === "Removed From Favorites") {
						dispatch(setLikeProject({ projectId: projectId }));
						toast.error(resp.message, {
							position: "top-right",
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: "light",
							transition: Bounce,
						});
					} else {
						toast.warning("Try Again After Sometime something went wrong", {
							position: "top-right",
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: "light",
							transition: Bounce,
						});
					}
				} else if (authFunctions?.details?.actionName === "toggleContentFav") {
					const params = {
						type: filters.type,
						config: filters.config,
						area: filters.area,
						minPrice: filters.minPrice,
						maxPrice: filters.maxPrice,
						possessionStatus: filters.possessionStatus,
						projectOffset: 0,
						contentOffset: 0,
						lastContentPosition: 0,
						screenSize: updateScreenSize(),
					};
					const response = await fetchAllProjects({ params });
					console.log(response.data);
					if (!response.data) {
						loginPurpose();
						return;
					} else {
						dispatch(
							setOffsets({
								newProjectOffset: response.nextProjectOffset,
								newContentOffset: response.nextContentOffset,
								lastContentPosition: response.lastPosition,
							})
						);
						dispatch(setProjects({ ...projects, ...response }));
					}
					const contentId = authFunctions?.details?.data?.contentId;
					const contentType = authFunctions?.details?.data?.contentType;
					const resp = await postPutFavoriteContent(contentId, contentType);
					console.log("Content Resp", resp);

					if (resp.message === "Added To Favorites") {
						console.log("Blog Fav");
						dispatch(setLikeContent({ contentId: contentId }));
						toast.info(resp.message, {
							position: "top-right",
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: "light",
							transition: Bounce,
						});
					} else if (resp.message === "Removed From Favorites") {
						dispatch(setLikeContent({ contentId: contentId }));
						toast.error(resp.message, {
							position: "top-right",
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: "light",
							transition: Bounce,
						});
					} else {
						toast.warning("Try Again After Sometime something went wrong", {
							position: "top-right",
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: "light",
							transition: Bounce,
						});
					}
				}
				//For Downloading
				else if (authFunctions?.details?.actionName === "downloadBrochure") {
					let resp2 = "";
					if (authFunctions?.details?.data?.projectId) {
						resp2 = await postLeadToProject(
							authFunctions?.details?.data?.projectId
						);
					}
					if (resp2.status === "success") {
						await handleDownload(authFunctions?.details?.data?.brochure);
						toast("Pdf Download Successful");
						dispatch(setDownloadState({ downloadState: false }));
					}
				}
			}
		} catch (err) {
			console.log(err);
		}
	};

	// Send OTP or Login if Lead exists
	const sendOtp = async (event) => {
		event.stopPropagation(); // Prevent event bubbling
		if (phone === "" || name === "") {
			setError("Phone number and name are required.");
			return;
		}
		if (!/^[6-9]\d{9}$/.test(phone)) {
			setError("Invalid Phone Number");
			return;
		}
		setError("");
		setSendingOTP(true);
		try {
			const response = await loginAndOtpRoutes({ phone, name, otp });
			if (response.message === "OTP Sent") {
				setError("");
				setStep(2); // Proceed to OTP input step
			} else {
				localStorage.setItem("lead_token", response.data.lead_token);
				setIsLoggedIn(true);
				closeLogin();
				setStep(1);
				setPhone("");
				setName("");
				loginPurpose();
				// window.location.reload();
				// You can change this as per logic if lead exists or continue with OTP flow
			}
		} catch (err) {
			setError("An error occurred while sending OTP or logging in.");
		}
		setSeconds(30);
		setTimeout(() => {
			setResendEnabled(true);
		}, 30002);
		setSendingOTP(false);
	};

	const resendOtp = async (event) => {
		event.stopPropagation();
    setResendEnabled(false);
		try {
			const response = await resendOtpRoutes({ phone });
		  setResendingFlag(true);
      console.log("Response OTP", response);
			if (response.status === "success") {
				setError(""); // Proceed to OTP input step
			} else {
				setError("Error sending OTP");
			
			}
		} catch (err) {
			setError("An error occurred while sending OTP or logging in.");
		}
		setResendingFlag(false);
    setSeconds(30);
    setTimeout(() => {
      setResendEnabled(true);
    }, 30002);
	};
	// Verify OTP
	const verifyOtp = async (event) => {
		event.stopPropagation(); // Prevent event bubbling
		event.preventDefault();
		if (otp === "") {
			setError("Please enter the OTP.");
			return;
		}
		try {
			const response = await loginAndOtpRoutes({ phone, name, otp });
			if (response.status < 250 || response.status === "success") {
				console.log(response.data);
				localStorage.setItem("lead_token", response.data.lead_token);
				setIsLoggedIn(true);
				closeLogin();
				setStep(1);
				setPhone("");
				setName("");
				loginPurpose();
				// window.location.reload();
			} else {
				setError("Invalid OTP. Please try again.");
			}
		} catch (err) {
			setError("An error occurred during OTP verification.");
		}
	};

	const handleChangeNumber = (event) => {
		event.stopPropagation(); // Prevent event bubbling
		setStep(1);
		setPhone("");
		setOtp("");
		setError("");
	};
	useEffect(() => {
		if (seconds > 0) {
			const timer = setInterval(() => {
				setSeconds((prevSeconds) => prevSeconds - 1);
			}, 1000);
			return () => clearInterval(timer);
		}
	}, [seconds]);
	if (!loginOpen) return null; // Only render if isOpen is true

	return (
		<div className='fixed inset-0 z-50 flex items-center justify-center bg-black backdrop-blur-[2.5px]'>
			{/* Background overlay with blur effect */}
			<div
				className='absolute inset-0 bg-black bg-opacity-50'
				onClick={() => {
					dispatch(reset());
					dispatch(setReset());
					closeLogin();
				}}></div>

			{/* Login Modal */}
			<div className='relative bg-base-100 rounded-[24px] shadow-lg border-[1px] border-base-800 p-10 pb-14 z-50 w-96 mx-5'>
				{/* Close button */}
				<button
					onClick={(event) => {
						event.stopPropagation();
						dispatch(reset());
						dispatch(setReset());
						closeLogin();
					}} // Prevent event bubbling
					className='absolute top-3 right-4 text-base-600 text-2xl font-bold'>
					<img src={`${assetsUrl}/assets/svgs/close.svg`} alt='Close' />
				</button>

				{/* Logo */}
				<div className='flex justify-center mb-2'>
					<img
						src={`${assetsUrl}/assets/svgs/monogram.svg`}
						alt='Logo'
						className='h-15 w-15'
					/>
				</div>

				{/* Welcome Message */}
				<h2 className='text-xl font-semibold text-center mb-4 text-base-600'>
					WELCOME
				</h2>
				<p className='text-center text-base-600 mb-14'>
					Please enter your details to login
				</p>

				{/* Step 1: Enter Phone and Name */}
				{step === 1 && (
					<form
						onSubmit={(e) => {
							e.preventDefault();
							sendOtp(e);
						}}>
						<div className='mb-4'>
							<label className='text-base-600 mb-2' htmlFor='name'>
								Name
							</label>
							<input
								type='text'
								id='name'
								value={name}
								onChange={(e) => setName(e.target.value)}
								className='border-2 border-base-1100 text-base-600 bg-white p-2 w-full rounded-md shadow-sm placeholder-gray-500 focus:outline-none focus:border-blue-500'
								placeholder='Enter your name'
							/>
						</div>
						<div className='mb-4'>
							<label className='text-base-600 mb-2' htmlFor='phone'>
								Phone Number
							</label>
							<input
								type='text'
								id='phone'
								value={phone}
								onChange={(e) => setPhone(e.target.value)}
								className='border-2 border-base-1100 text-base-600 bg-white p-2 w-full rounded-md shadow-sm placeholder-gray-500 focus:outline-none focus:border-blue-500'
								placeholder='Enter phone number'
							/>
						</div>
						{error && <p className='text-red-500 text-sm mb-4'>{error}</p>}
						<button
							type='submit'
							className={`w-full  text-base-100 font-semibold py-1.5 rounded-md hover:scale-105 transition-all ${
								sendingOTP ? "bg-base-500" : "bg-base-600"
							}`}
							disabled={sendingOTP}>
							{sendingOTP ? "Sending OTP..." : "Send OTP"}
						</button>
					</form>
				)}

				{/* Step 2: Enter OTP */}
				{step === 2 && (
					<form onSubmit={verifyOtp}>
						<div className='mb-4'>
							<label className='text-base-600 mb-2' htmlFor='otp'>
								OTP
							</label>
							<input
								type='text'
								id='otp'
								value={otp}
								onChange={(e) => setOtp(e.target.value)}
								className='border-2 border-base-1100 text-base-600 bg-white p-2 w-full rounded-md shadow-sm placeholder-gray-500 focus:outline-none focus:border-blue-500'
								placeholder='Enter OTP'
							/>
							<div className='w-full flex justify-end'>
								<button
									className={` ${
										resendEnabled
											? "text-blue-500 hover:underline"
											: "text-base-500"
									}`}
									onClick={resendOtp}
									disabled={!resendEnabled}>
									Resend OTP {seconds > 0 && `in ${seconds} secs`}
								</button>
							</div>
						</div>
						{/* Just add timer and Resend OTP logic here  */}
						{error && <p className='text-red-500 text-sm mb-4'>{error}</p>}
						<button
							type='submit'
							className='w-full bg-base-600 text-base-100 font-semibold py-1.5 rounded-md hover:bg-gray-800'>
							Verify OTP
						</button>

						{/* Change number link */}
						<div className='mt-4 text-center'>
							<button
								onClick={handleChangeNumber}
								className='text-blue-500 underline'>
								Change Number
							</button>
						</div>
					</form>
				)}
			</div>
		</div>
	);
};

export default Login;
